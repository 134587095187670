<template>
  <v-app>
    <v-main>
      <v-container fill-height>
        <slot />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "PlayerLayout",
};
</script>

<style>
.container {
  padding: 6px !important;
}
</style>
