import { uploadURL } from '@/utils/consts';
export default {
  data: () => ({
    uploadURL,
  }),
  methods: {
    getImageURL({ userId, _id }, type, size, img) {
      if (type === 'panorama')
        return `${uploadURL}${userId}/${_id}/${type}/${img}`
      return `${uploadURL}${userId}/${_id}/${type}/${size}/${img}`;
    },
    /* getImageURL({ userId, _id }, type, size, img) {
        console.log(userId, _id, type, size)
        if (type === 'images_360') {
            const tmp = img.split('/');
            tmp[tmp.length - 1] = 'copy_' + tmp[tmp.length - 1];
            return this.uploadURL + tmp.join('/');
        }
        return `${uploadURL}${img}`;
    }, */
  },
};
