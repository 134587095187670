import Vue from 'vue';
import Vuex from 'vuex';
import { login, getSpin } from "../api/index";
import axios from "../api/axios";
/* import jwt_decode from "jwt-decode";

 */

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: localStorage.getItem('accessToken') || null,
    isLoggedIn: false,
    snackbarText: '',
    snackbar: false,
    pins: [],
    panoPins: [],
    spin: {},
  },
  mutations: {
    SET_TOKEN: (state, payload) => {
      state.token = payload;
    },
    SET_SPIN: (state, payload) => {
      state.spin = payload;
      state.pins = payload.hotspots?.map((h) => h)
      state.panoPins = payload.panoHotspots?.map((h) => h)
    },
    SET_PINS: (state, payload) => {
      state.pins = payload;
    },
    SET_PANOPINS: (state, payload) => {
      state.panoPins = payload;
    },
  },
  actions: {
    login: async ({ commit }, { email, password }) => {
      const result = await login({
        email,
        password,
      });
      if (result && result.token) {
        const { token } = result;
        axios.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${token}`;
        localStorage.setItem('accessToken', token);
        commit('SET_TOKEN', token);
        //await dispatch('getUser');
      }
    },
    getSpin: async ({ commit }, id) => {
      const spin = await getSpin(id);
      commit('SET_SPIN', spin);
    },
    /*
    getUser: async ({ commit, state }) => {
      const user = await api.getUser();
      if (user) {
        const decoded = jwt_decode(state.token);
        if (decoded.role === 1) {
          commit("SET_STATUS", true);
        }
      }
    }, */
  },
  getters: {

  },
});
