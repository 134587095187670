import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/spins/:id',
      name: 'edit',
      meta: { layout: 'player' },
      component: () => import('@/views/SpinPreview'),
    },
    {
      path: '/',
      name: 'edit',
      meta: { layout: 'player' },
      component: () => import('@/views/SpinPreview'),
    },
    {
      path: '*',
      name: 'all',
      meta: { layout: 'player' },
      component: () => import('@/views/SpinPreview'),
    },
  ],
});

export default router;
