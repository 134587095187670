<template>
  <preview />
</template>
<script>
import Preview from "@/components/player/Main";
export default {
  components: {
    Preview,
  },
  /* head: {
    title: function () {
      return {
        inner: this.$route.query.test,
      };
    },
    meta: function () {
      return [
        // ...
        // Twitter
        { name: "twitter:title", content: "Content Title" },
        // with shorthand
        {
          n: "twitter:description",
          c: "Content description less than 200 characters",
        },
        { p: "og:title", content: this.$route.query.title },
        // with shorthand
        { p: "og:image", c: "https://spinmyride.tk/uploads/6106a902dd7ffc3447d6f0f3/61b43c140d30a2114b6558d8/images_360/resized/"+this.$route.query.img },
      ];
    },
  }, */
};
</script>