import axios from './axios';

export const getSpin = async (id) => {
  try {
    const res = await axios.get(`spins/${id}/public`);
    return res.data;
  } catch (error) {
    return {};
  }
};
