import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import i18n from './i18n';
import router from './router/index';
import store from './store';
import PlayerLayout from '@/layouts/Player';
import CoolLightBox from 'vue-cool-lightbox';
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css';
import vueCustomElement from 'vue-custom-element';

Vue.use(vueCustomElement);
Vue.use(CoolLightBox);
Vue.component('player-layout', PlayerLayout);

//Vue.config.productionTip = false;

App.vuetify = vuetify;
App.router = router;
App.store = store;
App.i18n = i18n;
Vue.customElement('spin-my-ride-widget', App);
