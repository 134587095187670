<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
import "@/assets/main.css";
export default {
  name: "App",
  computed: {
    layout() {
      return "player-layout";
    },
  },
};
</script>

<style>
.v-application--wrap {
  /* background: #e1eeff; */
  min-height: unset !important;
}

.title_1 {
  margin: 10px auto 0 auto;
  user-select: none;
  font-size: 1.25em;
  font-weight: 500;
}
</style>
