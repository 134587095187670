import axios from 'axios';
import router from '@/router';

// const baseURL = 'http://localhost:3000/';
// const baseURL = 'https://spinmyride.tk/';
const baseURL = 'https://api.spinmyride.net/';

const instance = axios.create({
  baseURL,
});

instance.defaults.headers.common[
  'Authorization'
] = `Bearer ${localStorage.getItem('accessToken')}`;

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      router.push('login');
    }
    return Promise.reject(error);
  }
);

export default instance;
